import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import * as actions from '../../../../RigsData/actions'
import { Spinner } from 'reactstrap'
import Ansi from 'ansi-to-react'
import { Modal, ModalBody } from 'reactstrap'

const SystemConsole = props => {
  const [systemModal, setSystemModal] = useState(false)

  useEffect(() => {
    props.setConsole({ rigId: props.worker.id, setConsole: true, setSystemConsole: true })
  }, [])

  useEffect(() => {
    const consoleInfo = setInterval(() => {
      props.getConsoleData({ rigId: props.worker.id, setSystemConsole: true })
    }, 10000)

    return () => {
      props.setConsole({ rigId: props.worker.id, setConsole: false })
      clearInterval(consoleInfo)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => systemLogToggle(), 10)
  }, [])

  const systemLogToggle = async () => {
    setSystemModal(!systemModal)
    if (systemModal) {
      setTimeout(() => props.onCancel(), 10)
    }
  }

  return (
    <Modal
      isOpen={systemModal}
      toggle={systemLogToggle}
      className={'modal-dialog-centered popup popup_large card_turbo '}
      onCancel={() => systemLogToggle()}
    >
      <ModalBody>
        <Row className={'worker_charts'}>
          <Col xs={12} className='worker-block worker-block_logs'>
            <div className='console_preloader'>
              <div className='status'>{!!props.getReqFromRigConsole && <Spinner color={'primary'} />}</div>
            </div>
            {props.getReqFromRigConsole ? (
              <pre className='rig_console' style={{ opacity: '0.2' }}>
                {props.rigConsole?.split('\n').map(log => {
                  return (
                    <div>
                      <Ansi>{log}</Ansi>
                    </div>
                  )
                })}
              </pre>
            ) : (
              <pre className='rig_console'>
                {props.rigConsole?.split('\n').map(log => {
                  return (
                    <div>
                      <Ansi>{log}</Ansi>
                    </div>
                  )
                })}
              </pre>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = store => ({
  rigConsole: store.rigs.rig.rigConsole,
  getReqFromRigConsole: store.rigs.rig.getReqFromRigConsole,
})

const mapDispatchToProps = {
  setConsole: actions.setConsole,
  getConsoleData: actions.getConsoleData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemConsole)
