import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Label, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import * as actions from '../actions'
import CritTempSection from './CritTempSection'
import { Modal } from 'reactstrap'

const GeneralSettingsTab = props => {
  const { t } = useTranslation('common')
  const [use_data_centers_and_rooms, set_use_data_centers_and_rooms] = useState(false)
  const [use_external_devices_to_compare_power_consumption, set_use_external_devices_to_compare_power_consumption] =
    useState(false)
  const [
    use_external_pdu_devices_for_worker_power_management,
    set_use_external_pdu_devices_for_worker_power_management,
  ] = useState(false)
  const [globally_use_amos_watchdog, set_globally_use_amos_watchdog] = useState(false)
  const [globally_use_autofan_by_default, set_globally_use_autofan_by_default] = useState(false)
  const [external_fan_controlled_by, set_external_fan_controlled_by] = useState({
    value: '1',
    label: t('pages.settings.temperature'),
  })
  const [high_temperature, set_high_temperature] = useState(props.settings.highTemp)
  const [critical_temperature, set_critical_temperature] = useState(props.settings.critTemp)
  const [worker_action, set_worker_action] = useState({ value: '1', label: t('pages.settings.stopMiner') })
  const [applyModal, setApllyModal] = useState(false)
  const [applyForAllRigs, setApplyForAllRigs] = useState(false)

  useEffect(() => {
    props.setAutofan({ type: 'global' })
    props.setGeneralSettings({ type: 'global' })
  }, [])

  useEffect(() => {
    set_critical_temperature(props.settings.critTemp)
    set_high_temperature(props.settings.highTemp)
    if (props.settings.critTempAction === 'Stop Miner') {
      set_worker_action({ value: '1', label: t('pages.settings.stopMiner') })
    }
    if (props.settings.critTempAction === 'Power Off') {
      set_worker_action({ value: '5', label: t('pages.settings.powerOff') })
    }
    if (props.settings.critTempAction === 'Reboot') {
      set_worker_action({ value: '6', label: t('pages.settings.reboot') })
    }
    if (props.settings.critTempAction === 'Exclude GPU') {
      set_worker_action({ value: '2', label: t('pages.settings.excludeGPU') })
    }
    if (props.settings.critTempAction === 'Nothing') {
      set_worker_action({ value: '4', label: t('pages.settings.nothing') })
    }
  }, [props.settings.critTemp, props.settings.critTempAction, props.settings.highTemp])

  const applySettings = async () => {
    setApllyModal(!applyModal)
  }

  const setApplyData = async () => {
    await props.updateGeneralSettings({
      critTemp: +critical_temperature,
      highTemp: +high_temperature,
      critTempAction: worker_action.label,
      updateAllRigs: applyForAllRigs,
    })

    setApllyModal(false)
  }

  return (
    <div className={'settings__general'}>
      <div style={{ opacity: '0.2' }}>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={true}
              onChange={() => set_use_data_centers_and_rooms(!use_data_centers_and_rooms)}
              disabled={use_data_centers_and_rooms}
            />
            {t('pages.settings.useDataCentersRooms')}
          </Label>
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={true}
              onChange={() =>
                set_use_external_devices_to_compare_power_consumption(
                  !use_external_devices_to_compare_power_consumption,
                )
              }
            />
            {t('pages.settings.useExternalDevices')}
          </Label>
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={true}
              onChange={() =>
                set_use_external_pdu_devices_for_worker_power_management(
                  !use_external_pdu_devices_for_worker_power_management,
                )
              }
            />
            {t('pages.settings.UseExternalPDU')}
          </Label>
        </div>
        <hr />
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={true}
              onChange={() => set_globally_use_amos_watchdog(!globally_use_amos_watchdog)}
            />
            {t('pages.settings.globallyAMOSWatchdog')}
          </Label>
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>
            <input
              type={'checkbox'}
              checked={true}
              onChange={() => set_globally_use_autofan_by_default(!globally_use_autofan_by_default)}
            />
            {t('pages.settings.globallyAutoFANDefault')}
          </Label>
        </div>
      </div>

      <div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>{t('pages.settings.externalFANControlled')}</Label>
          <Select
            classNamePrefix={'form_control_react_select'}
            options={[{ value: '1', label: t('pages.settings.temperature') }]}
            value={external_fan_controlled_by}
            defaultValue={''}
            placeholder={t('inputs.select')}
            onChange={option => set_external_fan_controlled_by(option)}
          />
        </div>

        <div className={'settings__dashed'}>
          <CritTempSection
            critical_temperature={critical_temperature}
            set_critical_temperature={set_critical_temperature}
            worker_action={worker_action}
            set_worker_action={set_worker_action}
          />
        </div>
        <div className={'settings__row'}>
          <Label className={'checkboxLabel'}>{t('pages.settings.highTemperature')}</Label>
          <Input
            placeholder={'60'}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            value={high_temperature ? high_temperature : '60'}
            onChange={e => set_high_temperature(e.target.value)}
          />
        </div>
        <div className='settings_row'>
          <Button
            color='primary'
            onClick={() => {
              applySettings()
            }}
          >
            {t('pages.miningPresets.apply')}
          </Button>

          <Modal isOpen={applyModal} className={'modal-dialog-centered'}>
            <ModalHeader tag={'h4'}>{'Do you want to apply these values?'}</ModalHeader>
            <ModalBody>
              <input
                type={'checkbox'}
                checked={applyForAllRigs}
                onChange={() => setApplyForAllRigs(!applyForAllRigs)}
              />{' '}
              {`Reset temperature pointers to those values ${high_temperature}/${critical_temperature} for all rigs`}
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={() => setApplyData()}>
                {t('pages.workers.yes')}
              </Button>
              <Button color='secondary' onClick={() => setApllyModal()}>
                {t('pages.workers.no')}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  settings: store.settings,
})

const mapDispatchToProps = {
  setAutofan: actions.setAutofan,
  setGeneralSettings: actions.setGeneralSettings,
  updateGeneralSettings: actions.updateGeneralSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsTab)
