import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import * as actions from '../../../RigsData/actions'
import * as actionsProfiles from '../../../OverclockingProfiles/actions'
import OverclockingTab from './OverclockingTab'
import { Modal, ModalBody } from 'reactstrap'
import OverclockingProfileTab from './OverclockingProfileTab'

const CardTurboModal = props => {
  const { t } = useTranslation('common')
  const [rig, setRig] = useState({})
  const [modal, setModal] = useState(false)
  const setOverclockingData = props.setOverclockingData

  useEffect(() => {
    setTimeout(() => toggle(), 10)
  }, [])

  useEffect(() => {
    setOverclockingData({ rigId: props.rigId })
    props.setOverclockingProfilesList({ page: 1, size: 1000 })
  }, [setOverclockingData, props.rigId])

  useEffect(() => {
    if (modal) {
      setRig(props.overclockingData)
    }
  }, [modal, props.overclockingData])

  const toggle = async () => {
    setModal(!modal)
    if (modal) {
      await setOverclockingData({ rigId: null })
      setTimeout(() => props.onCancel(), 10)
    }
  }
  const updateRig = () => {
    setOverclockingData({ rigId: props.rigId })
  }

  return (
    modal && (
      <Modal isOpen={modal} toggle={toggle} className={'modal-dialog-centered popup popup_large card_turbo '}>
        <ModalBody>
          <Tabs forceRenderTabPanel>
            <TabList className={'card_tabs'}>
              <Tab>{t('pages.workers.overclocking')}</Tab>
              <Tab>{t('pages.workers.profiles')}</Tab>
            </TabList>
            <TabPanel className={'react-tabs__tab-panel card_tabs_first_panel'}>
              <OverclockingTab rig={rig} toggle={toggle} rigName={props.rigName} />
            </TabPanel>
            <TabPanel className={'react-tabs__tab-panel card_tabs_first_panel profiles_tab'}>
              <OverclockingProfileTab rig={rig} toggle={toggle} updateRig={updateRig} />
            </TabPanel>
          </Tabs>
        </ModalBody>
      </Modal>
    )
  )
}

const mapStateToProps = store => ({
  algoCoinsList: store.profiles.algoCoinsList,
  overclockingData: store.rigs.overclockingData,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
})

const mapDispatchToProps = {
  overclocking: actions.overclocking,
  overclockingEach: actions.overclockingEach,
  setOverclockingData: actions.setOverclockingData,
  saveOverclockingProfile: actionsProfiles.saveOverclockingProfile,
  setOverclockingProfilesList: actionsProfiles.setOverclockingProfilesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardTurboModal)
