import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Modal } from 'components'
import * as actions from '../../../../RigsData/actions'
import * as miningPresetActions from '../../../../MiningPresets/actions'
import { useTranslation } from 'react-i18next'
import { CheckListErrors } from './CheckListErrors'

///////////////////////////////////////////// hashList need move to props
const hashList = ({ averageHashList = [], cName, cMem }) =>
  averageHashList.find(vcard => {
    if (cName.includes('super')) {
      if (cName.includes('1660') && vcard.cardName === '1660 S') {
        return vcard.cardName
      } else if (cName.includes('2060') && vcard.cardName === '2060 S') {
        return vcard.cardName
      } else if (cName.includes('2070') && vcard.cardName === '2070 S') {
        return vcard.cardName
      } else if (cName.includes('2080') && vcard.cardName === '2080 S') {
        return vcard.cardName
      }
    } else if (cName.includes('ti')) {
      if (cName.includes('1070') && vcard.cardName === '1070 Ti') {
        return vcard.cardName
      } else if (cName.includes('1080') && vcard.cardName === '1080 Ti') {
        return vcard.cardName
      } else if (cName.includes('1660') && vcard.cardName === '1660 Ti') {
        return vcard.cardName
      } else if (cName.includes('2080') && vcard.cardName === '2080 Ti') {
        return vcard.cardName
      } else if (cName.includes('3060') && vcard.cardName === '3060 Ti') {
        return vcard.cardName
      } else if (cName.includes('3070') && vcard.cardName === '3070 Ti') {
        return vcard.cardName
      } else if (cName.includes('3080') && vcard.cardName === '3080 Ti') {
        return vcard.cardName
      }
    } else if (cName.includes('lhr')) {
      if (cName.includes('3060') && vcard.cardName === '3060 LHR') {
        return vcard.cardName
      } else if (cName.includes('3070') && vcard.cardName === '3070 LHR') {
        return vcard.cardName
      } else if (cName.includes('3080') && vcard.cardName === '3080 LHR') {
        return vcard.cardName
      }
    } else if (cName.includes('470') && cMem.includes('8192')) {
      if (vcard.cardName === '470 8GB') return vcard.cardName
    } else if (cName.includes('570') && cMem.includes('8192')) {
      if (vcard.cardName === '570 8GB') return vcard.cardName
    } else {
      return cName.includes(vcard.cardName.toLowerCase())
    }
    return vcard
  })

////////////////////////////////////////////////////////////////////////////

const Checklist = props => {
  const { t } = useTranslation('common')
  const [showAlertIgnore, setShowAlertIgnore] = useState(false)
  const [ignoreError, setIgnoreError] = useState()
  const [detectedCard, setDetectedCard] = useState(props.worker.videoCards)
  const setAverageHashList = props.setAverageHashList
  const [workerErrors, setWorkerErrors] = useState(props.errors)

  useEffect(() => {
    setAverageHashList({ page: 1, size: 100 })
    props.getRigErrors({ rigId: +props.worker.id })
  }, [setAverageHashList])

  useEffect(() => {
    setWorkerErrors(props.errors)
  }, [props.errors])

  useEffect(() => {
    let hashRateState = 0
    if (props.worker.videoCards && props.worker.videoCards.length) {
      const newDetectedCard = props.worker.videoCards.map(item => {
        const cName = item.gpuName.toLowerCase()
        const cMem = item.vram.toLowerCase()
        const checkHash = hashList({
          averageHashList: props.averageHashList,
          cName,
          cMem,
        })

        if (detectedCard.length && checkHash && props.worker.algo) {
          hashRateState = 100 - (item.hashCard / checkHash[`${props.worker.algo}`]) * 100
        }
        if (detectedCard.length && checkHash && !props.worker.algo) {
          hashRateState = 100 - (item.hashCard / checkHash.ethash) * 100
        }

        return {
          ...item,
          hashRateState,
        }
      })
      setDetectedCard(newDetectedCard)
    }
  }, [detectedCard.length, props.averageHashList, props.worker.algo, props.worker.videoCards])

  const rejectedCards = []
  const invalidCards = []
  const notAcceptedCards = []
  const lowHashCards = []
  const lowerHashCards = []
  const offlineCards = []

  detectedCard.forEach(card => {
    if (props.worker.active) {
      if (card.badBlocsNumber >= 50) {
        rejectedCards.push({
          idx: card.cardNum,
          rejects: card.badBlocsNumber,
        })
      }
      if (card.invalidShares >= 50) {
        invalidCards.push({
          idx: card.cardNum,
          invalid: card.invalidShares,
        })
      }

      if (card.invalidShares > 0 && card.blocksNumber === 0) {
        notAcceptedCards.push({
          idx: card.cardNum,
        })
      }

      if (card.hashRateState <= 10 && card.hashRateState >= 5) {
        lowHashCards.push({
          idx: card.cardNum,
          mhs: card.hashCard,
        })
      }

      if (props.worker && props.worker.videoCards && card.detected && card.hashRateState > 10) {
        lowerHashCards.push({
          idx: card.cardNum,
          mhs: card.hashCard,
        })
      }
      if (props.worker && !card.detected) {
        offlineCards.push({ idx: card.cardNum })
      }
    }
  })

  const onHandleIgnore = async (error, action) => {
    const errorsToIgnore = []
    let fallenOffTheBusErrorId = null
    props.rigErrors.map(item => {
      if (item.error === error.error) {
        if (item.error.includes('fallen off the bus')) {
          fallenOffTheBusErrorId = item.id
        }
        errorsToIgnore.push(item.id)
      }
      return item
    })

    const ignoreData = {
      errorsIds: errorsToIgnore.toString(),
      action: action,
      fallenOffTheBusErrorId,
      rigId: props.worker.id,
      userId: props.worker.userId,
    }

    const isIgnored = await props.updateRigError(ignoreData)
    isIgnored && setShowAlertIgnore(false)
    isIgnored && props.delayRigUpdate(true)
  }

  const ignore = error => {
    setShowAlertIgnore(true)
    setIgnoreError(error)
  }

  return (
    <div>
      <Modal
        title={t('pages.workers.ignoreError')}
        onFinish={() => onHandleIgnore(ignoreError, 'ignore')}
        onFinish2={() => onHandleIgnore(ignoreError, 'erase')}
        finishTitle={t('pages.workers.ignore')}
        finishTitle2={t('pages.workers.erase')}
        isOpen={showAlertIgnore}
        onCancel={() => setShowAlertIgnore(false)}
        hideCanselBtn
        className='checklist__ignore-modal'
      >
        {t('pages.workers.forNext24Hrs')}
      </Modal>

      <Row className={'worker_charts'}>
        <Col xs={12} className='worker-block'>
          {!props.worker.active && (
            <div className='checklist-row checklist-row_red'>
              <span className='checklist-status'>
                <i className='far fa-wifi-slash checklist-status__icon'></i>
                <p className='checklist-row__descr'>{t('pages.workers.workerNotConnected')}</p>
              </span>
              <div className='checklist-row__nav'>
                <button className='checklist-row__nav-btn'>{t('pages.workers.submit')}</button>
                <button className='checklist-row__nav-btn'>{t('pages.workers.ignore')}</button>
              </div>
            </div>
          )}

          {workerErrors && workerErrors.length > 0
            ? workerErrors.map(error => {
                return (
                  <CheckListErrors
                    error={error}
                    cards={detectedCard}
                    goToActivityLogs={props.goToActivityLogs}
                    ignore={ignore}
                    key={error.id}
                  />
                )
              })
            : ''}

          {rejectedCards.length > 0 && (
            <div className='checklist-row'>
              <span className='checklist-status'>
                <i className='fas fa-ban checklist-status__icon'></i>
                <p className='checklist-row__descr'>
                  {rejectedCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}GPU${curr.idx}`, '')} have too much
                  rejected shares ({rejectedCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}${curr.rejects}`, '')}
                  ). {t('pages.workers.checkOverclockingInternetConnection')}.
                </p>
              </span>
            </div>
          )}
          {invalidCards.length > 0 && (
            <div className='checklist-row checklist-row_red'>
              <span className='checklist-status'>
                <i className='fas fa-ban checklist-status__icon'></i>
                <p className='checklist-row__descr'>
                  {invalidCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}GPU${curr.idx}`, '')} have too much
                  invalid shares ({invalidCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}${curr.invalid}`, '')}
                  ). {t('pages.workers.CheckOverclockingTestVideoMemory')}.
                </p>
              </span>
            </div>
          )}
          {notAcceptedCards.length > 0 && (
            <div className='checklist-row checklist-row_red'>
              <span className='checklist-status'>
                <i className='fas fa-magic checklist-status__icon'></i>
                <p className='checklist-row__descr'>
                  {notAcceptedCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}GPU${curr.idx}`, '')}{' '}
                  {notAcceptedCards.length === 1
                    ? ' ' + t('pages.workers.isCompletelyNotEfficient') + '.'
                    : ' ' + t('pages.workers.areCompletelyNotEfficient') + '.'}
                </p>
              </span>
            </div>
          )}

          {lowHashCards.length > 0 && (
            <div className='checklist-row'>
              <span className='checklist-status'>
                <i className='far fa-chart-line-down checklist-status__icon'></i>
                <p className='checklist-row__descr'>
                  {lowHashCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}GPU${curr.idx}`, '')} have low hashrate
                  now ({lowHashCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}${curr.mhs}MH/s`, '')}
                  ). Check overclocking and
                  {lowHashCards.length === detectedCard.length
                    ? ' ' + t('pages.workers.internetConnection') + '.'
                    : ' ' + t('pages.workers.minerSettings') + '.'}
                </p>
              </span>
            </div>
          )}
          {lowerHashCards.length > 0 && (
            <div className='checklist-row checklist-row_red'>
              <span className='checklist-status'>
                <i className='far fa-chart-line-down checklist-status__icon'></i>
                <p className='checklist-row__descr'>
                  {lowerHashCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}GPU${curr.idx}`, '')}{' '}
                  {t('pages.workers.haveTooLowHashrate')} (
                  {lowerHashCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}${curr.mhs}MH/s`, '')}
                  ). Check overclocking and
                  {lowerHashCards.length === detectedCard.length
                    ? ' ' + t('pages.workers.internetConnection') + '.'
                    : ' ' + t('pages.workers.minerSettings') + '.'}
                </p>
              </span>
            </div>
          )}
          {offlineCards.length > 0 && (
            <div className='checklist-row checklist-row_red'>
              <span className='checklist-status'>
                <i className='far fa-puzzle-piece checklist-status__icon'></i>
                <p className='checklist-row__descr'>
                  {offlineCards.reduce((acc, curr) => `${acc ? `${acc}, ` : ''}GPU${curr.idx}`, '')}{' '}
                  {offlineCards.length === 1
                    ? ' ' + t('pages.workers.isOfflineNow') + '.'
                    : ' ' + t('pages.workers.areOfflineNow') + '.'}
                  {t('pages.workers.TryToPowerCycle')}.
                </p>
              </span>
            </div>
          )}

          {props.worker.rebootNum >= 50 && (
            <div className='checklist-row'>
              <span className='checklist-status'>
                <i className='fas fa-sync checklist-status__icon'></i>
                <p className='checklist-row__descr'>
                  {t('pages.workers.tooManyReboots')} ({props.worker.rebootNum})
                </p>
              </span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = store => ({
  averageHashList: store.presets.averageHashList,
  errors: store.rigs.rig.rigErrors,
})

const mapDispatchToProps = {
  deleteRig: actions.deleteRig,
  setRigMinerAction: actions.setRigMinerAction,
  resetOrPowerOfWorker: actions.resetOrPowerOfWorker,
  updateRigError: actions.updateRigError,
  delayRigUpdate: actions.delayRigUpdate,
  getRigErrors: actions.getRigErrors,
  setAverageHashList: miningPresetActions.setAverageHashList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist)
