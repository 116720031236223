import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../../RigsData/actions'
import cardsTemp from '../../../../../cardsTemp'
import { useTranslation } from 'react-i18next'

const MobileCard = ({ card, worker, removeCard, offMining }) => {
  const { t } = useTranslation('common')
  const tryRequire = icon => {
    try {
      return require('../../../../../assets/images/coinIcons/' + icon.toLowerCase() + '.png').default
    } catch (err) {
      return require('../../../../../assets/images/coinIcons/none.png').default
    }
  }
  const [showFull, setShowFull] = useState(false)
  const toggleFull = () => {
    setShowFull(!showFull)
  }
  const checkTime = new Date().getTime() - new Date(card.updatedAt).getTime()
  return (
    <div
      onClick={() => toggleFull()}
      className={showFull ? 'worker-card-mobile show-full' : 'worker-card-mobile'}
      key={card.showFull + card.id}
    >
      <div className={'worker-card-mobile__information'}>
        <div className={''}>
          {t('pages.workers.GPU')}
          {card.detected === false || card.uuid === 'GPUx' || checkTime > 40000 ? '' : card.cardNum}{' '}
          <span className='card__vendor'>{card.vendorName}</span>{' '}
          <span
            className={`card__name
                              ${card.cardType === 'amd' ? 'card__name_amd' : ''}`}
          >
            {card.gpuName}
          </span>
        </div>
        <div className={'worker-card-mobile__information-full'}>
          {card.cardSlot.substring(5)}&nbsp;{card.memType && <span className='card__memType'>{card.memType}</span>}
          &nbsp;{`${!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}`}
          <br />
          {card.memInfo && <span className='card__memType'>{card.memInfo}</span>}
        </div>
        {worker.active && card.cardDetected && (
          <div>
            <span
              className={
                +card.gpuTemp > cardsTemp.load && +card.gpuTemp < cardsTemp.max
                  ? 'orange'
                  : +card.gpuTemp >= cardsTemp.max
                  ? 'red'
                  : ''
              }
            >
              {worker.active && card.cardDetected && card.gpuTemp}
            </span>
            ℃ {worker.active && card.cardDetected && card.powerInfo && card.powerInfo}W {card.fanInfo}%
            {worker.active && ((!card.gpuTemp && !card.powerInfo && checkTime > 90000) || card.uuid === 'GPUx') ? (
              <span className='card__off'>(FAULT)</span>
            ) : (
              ''
            )}
          </div>
        )}
        <div className={'worker-card-mobile__information-full'}>
          G: {card.gpuClockStat}Mhz M: {card.memClockStat}Mhz {card.coreVddc && <>vC: {card.coreVddc}mV</>}
        </div>
      </div>
      <div className={'worker-card-mobile__information'}>
        {card.detected === false || checkTime > 30000 ? <span className='card__off'> (OFFLINE)</span> : ''}
        {worker.active && card.cardDetected && worker.gpuTotalSpeed > 0 && (
          <div>
            <img className={'worker_coin_icon'} alt={worker.coin} src={tryRequire(worker.coin)} /> {card.hashCardString}
          </div>
        )}
        {worker.active && card.cardDetected && worker.gpuTotalSpeedDual > 0 && (
          <div>
            <img className={'worker_coin_icon'} alt={worker.coinDual} src={tryRequire(worker.coinDual)} />{' '}
            {card.hashCardStringDual}
          </div>
        )}
        {worker.active && card.cardDetected && worker.gpuTotalSpeed > 0 && (
          <div>
            A: {card.blocksNumber > 0 ? card.blocksNumber : '0'}
            {card.blocksNumberDual && <>/{card.blocksNumberDual > 0 ? card.blocksNumberDual : '0'}</>}{' '}
            <span className='orange'>R</span>: {card.badBlocsNumber > 0 ? card.badBlocsNumber : '0'}
            {card.badBlocsNumberDual && <>/{card.badBlocsNumberDual > 0 ? card.badBlocsNumberDual : '0'}</>}{' '}
            <span className='red'>I</span>: {card.invalidShares > 0 ? card.invalidShares : '0'}
            {card.invalidSharesDual && <>/{card.invalidSharesDual > 0 ? card.invalidSharesDual : '0'}</>}
          </div>
        )}
      </div>
      {worker.active && card.cardDetected && (
        <div className={'worker-card-mobile__information worker-card-mobile__information-minified'}>
          <span>G: {card.gpuClockStat} Mhz</span>
          <span>M: {card.memClockStat}Mhz</span>
          {card.coreVddc && <span>vC: {card.coreVddc}mV</span>}
        </div>
      )}
      <div className={'worker-card-mobile__actions-errors'}>
        <div className={'worker-card-mobile__errors'}>
          {worker.active && card.cardDetected && (
            <div className={'worker_statuses'}>
              {/* //////////////////////////////////////////////////////// card statuses */}
              {(card.hashRateState > (worker.rigSettings ? worker.rigSettings.critHashScope : 10) ||
                card.hashCard === '0') &&
                !card.newCard &&
                card.hashCard !== '' && (
                  <span className='rejected' tooltip={t('pages.workers.lowHashrate')} flow={'left'}>
                    <i className='far fa-chart-line-down' style={{ color: 'red' }}></i>
                  </span>
                )}

              {card.hashRateState <= (worker.rigSettings ? worker.rigSettings.critHashScope : 10) &&
                card.hashRateState >= (worker.rigSettings ? worker.rigSettings.lowHashScope : 5) &&
                !card.newCard && (
                  <span className='rejected' tooltip={t('pages.workers.lowHashrate')} flow={'left'}>
                    <i className='far fa-chart-line-down' style={{ color: 'yellow' }}></i>
                  </span>
                )}

              {card.newCard && (
                <span className='rejected' tooltip={t('pages.workers.noOverclocking')} flow={'left'}>
                  <i className='fas fa-sliders-h' style={{ color: 'yellow' }}></i>
                </span>
              )}

              {+card.gpuTemp > cardsTemp.load && +card.gpuTemp < cardsTemp.max ? (
                <span className='rejected card__status' tooltip={t('pages.workers.highTemperature')} flow={'left'}>
                  <i className='fas fa-temperature-high' style={{ color: 'yellow' }}></i>
                  {/* <small>{card.gpuTemp}°</small> */}
                </span>
              ) : (
                ''
              )}
              {+card.gpuTemp >= cardsTemp.max ? (
                <span className='rejected card__status' tooltip={t('pages.workers.criticalTemperature')} flow={'left'}>
                  <i className='fas fa-burn' style={{ color: 'red' }}></i>
                  {/* <small>{card.gpuTemp}°</small> */}
                </span>
              ) : (
                ''
              )}

              {/* //////////////////////////////////////////////////// end card statuses */}
            </div>
          )}
        </div>
        <div className={'worker-card-mobile__actions'} onClick={e => e.stopPropagation()}>
          <div
            className={'actions__item actions__item_card'}
            tooltip={t('pages.workers.deleteCard')}
            flow={'left'}
            onClick={() => removeCard(card.id)}
          >
            <i className='fas fa-trash'></i>
          </div>
          {card.offMining ? (
            <div
              className={'actions__item actions__item_card'}
              tooltip={t('pages.workers.includeCard')}
              flow={'left'}
              onClick={() => offMining(card.id, false)}
            >
              <i className='fas fa-long-arrow-alt-up icon-md'></i>
            </div>
          ) : (
            <div
              className={'actions__item actions__item_card'}
              tooltip={t('pages.workers.excludeCard')}
              flow={'left'}
              onClick={() => offMining(card.id, true)}
            >
              <i className='fas fa-long-arrow-alt-down icon-md'></i>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  deleteRig: actions.deleteRig,
  setRigMinerAction: actions.setRigMinerAction,
  resetOrPowerOfWorker: actions.resetOrPowerOfWorker,
}

export default connect(null, mapDispatchToProps)(MobileCard)
