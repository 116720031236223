import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { addAlert } from '../../store/layout/actions'

const SET_RIGS_LIST = `
  query rigsList($page: Int, $size: Int, $orderBy: String, $orderDirection: String, $favoriteFirst: Boolean, $type: String, $search: String) {
    rigsList(page: $page, size: $size, orderBy: $orderBy, orderDirection: $orderDirection, favoriteFirst: $favoriteFirst, type: $type, search: $search) {
      items {
        id
        name
        password
        dcId
        roomId
        miningPresetId
        miningPresetName
        active
        action
        algo
        miner
        secondMiner
        coin
        coinDual
        powerOf
        reboot
        lowHash
        backLight
        gpuTotalSpeed
        gpuTotalSpeedDual
        gpuTotalShares
        gpuRej
        gpuInv
        gpuTotalTime
        errorsTotal
        rigMac
        downloadMiner
        updatedAt
        unit
        unitDual
        powerInfo
        avgFanValue
        nvidiaCards
        amdCards
        uptime
        autofanId
        hashRate
        hashRateValue
        effShares
        videoCards {
          rigId
          cardNum
          cardType
          detected
          hashCard
          fanInfo
          gpuTemp
          memTemp
          hashCardDual
          powerInfo
          newCard
          badBlocsNumber
          invalidShares
          offMining
          critTemp
          highTemp
          updatedAt
          lowHash
        }
      }
      total
    }
  }
`

const DOP_FILTER_RIGS = `
  query dopFiltersRigs($page: Int, $size: Int, $orderBy: String, $orderDirection: String, $favoriteFirst: Boolean, $roomId: Int, $dcId: Int, $status: String, $walletsIds: String, $coins: String, $minersIds: String, $poolsIds: String, $mpsIds: String, $hashMin: Int, $hashMax: Int, $tempMin: Int, $tempMax: Int, $rejectedShares: Boolean) {
    dopFiltersRigs(page: $page, size: $size, orderBy: $orderBy, orderDirection: $orderDirection, favoriteFirst: $favoriteFirst, dcId: $dcId, roomId: $roomId, status: $status, walletsIds: $walletsIds, coins: $coins, minersIds: $minersIds, poolsIds: $poolsIds, mpsIds: $mpsIds, hashMin: $hashMin, hashMax: $hashMax, tempMin: $tempMin, tempMax: $tempMax, rejectedShares: $rejectedShares) {
      items {
        id
        name
        password
        dcId
        roomId
        miningPresetId
        miningPresetName
        active
        action
        algo
        miner
        secondMiner
        coin
        coinDual
        powerOf
        reboot
        lowHash
        backLight
        gpuTotalSpeed
        gpuTotalSpeedDual
        gpuTotalShares
        gpuRej
        gpuInv
        gpuTotalTime
        uptime
        errorsTotal
        rigMac
        downloadMiner
        updatedAt
        unit
        unitDual
        powerInfo
        avgFanValue
        nvidiaCards
        amdCards
        autofanId
        hashRate
        hashRateValue
        effShares
        videoCards {
          rigId
          cardNum
          cardType
          detected
          hashCard
          fanInfo
          gpuTemp
          memTemp
          hashCardDual
          powerInfo
          newCard
          offMining
          badBlocsNumber
          badBlocsNumberDual
          invalidShares
          invalidSharesDual
          critTemp
          highTemp
          updatedAt
        }
        
  
      }
      total
    }
  }
`

const FILTER_RIGS = `
  query filterRigs($page: Int, $size: Int, $orderBy: String, $orderDirection: String, $favoriteFirst: Boolean, $roomId: Int, $dcId: Int, $online: Boolean, $offline: Boolean, $haveAlerts: Boolean, $notDetectedGPU: Boolean, $amdGPU: Boolean, $nvidiaGPU: Boolean, $highTempGPU: Boolean, $otherProblemsGPU: Boolean, $minTemp: Boolean, $maxTemp: Boolean, $offlineGPU: Boolean) {
    filterRigs(page: $page, size: $size, orderBy: $orderBy, orderDirection: $orderDirection, favoriteFirst: $favoriteFirst, dcId: $dcId, roomId: $roomId, online: $online, offline: $offline, haveAlerts: $haveAlerts, notDetectedGPU: $notDetectedGPU, amdGPU: $amdGPU, nvidiaGPU: $nvidiaGPU, highTempGPU: $highTempGPU, otherProblemsGPU: $otherProblemsGPU, minTemp: $minTemp, maxTemp: $maxTemp, offlineGPU: $offlineGPU) {
      items {
        id
        name
        password
        dcId
        roomId
        miningPresetId
        miningPresetName
        active
        action
        algo
        miner
        secondMiner
        coin
        coinDual
        powerOf
        reboot
        lowHash
        backLight
        gpuTotalSpeed
        gpuTotalSpeedDual
        gpuTotalShares
        gpuRej
        gpuInv
        gpuTotalTime
        uptime
        errorsTotal
        rigMac
        downloadMiner
        updatedAt
        unit
        unitDual
        powerInfo
        avgFanValue
        nvidiaCards
        amdCards
        autofanId
        hashRate
        hashRateValue
        effShares
        videoCards {
          rigId
          cardNum
          cardType
          detected
          hashCard
          fanInfo
          gpuTemp
          memTemp
          hashCardDual
          powerInfo
          newCard
          offMining
          badBlocsNumber
          badBlocsNumberDual
          invalidShares
          invalidSharesDual
          critTemp
          highTemp
          updatedAt
        }
      }
      total
    }
  }
`

const SET_RIGS_DATA = `
  query allRigsData {
    allRigsData {
      gpu_consumption
      rig_count_offline
      rig_count_alert
      card_count
      card_count_nvidia
      card_count_amd
      card_count_offline
      not_detected_cards
      problem_cards
      hot_cards
      rig_count
      avg_temp
      min_temp
      max_temp
      algorithms {
          name
          value
          unit
      }
    }
  }
`

const SET_OVERCLOCKING_DATA = `
query rigOverclocking($rigId: ID) {
  rigOverclocking(rigId: $rigId) {
    id
    userId
    name
    active
    action
    algo
    overclockingAlgo
    onGodAnEthArgPill
    autoTune
    p0
    amdmemorytweak
    overclockingPresetType
    statusDate
    overAction
    videoCards {
      id
      cardNum
      cardType
      cardSlot
      gpuName
      vram
      vendor
      brand
      gpuClock
      memClock
      powerLimit
      coreVddc
      fan
      powerInfo
      coreVddc
      dpm
      mdpm
      memVddc
      detected
      newCard
      vendorName
      memInfo
      memType
      fwInfo
      nvGpuPwrMin
      nvGpuPwrMax
      DefPwrLimit
      socFrequency
      memControlVoltage
      socVDDmax
      updatedAt
    }
    overclockingAllCardsData {
      id
      gpuClock
      memClock
      fan
      powerLimit
      coreVddc
      memVddc
      dpm
      mdpm
      socFrequency
      memControlVoltage
      socVDDmax
    }
  }
}
`

const SET_WORKER_TIME = `
query rigUptimeDowntime($rigId: ID) {
  rigUptimeDowntime(rigId: $rigId) {
    id
    downTime
    uptime
  }
}
`

const SET_WORKER_INFORMATION = `
query rigFront($rigId: ID) {
  rigFront(rigId: $rigId) {
    id
    userId
    rigSettingsId
    name
    password
    rigMac
    description
    ip
    cpu
    disk
    mb
    unit
    unitDual
    ram
    active
    action
    algo
    gpuTotalSpeed
    gpuTotalSpeedDual
    gpuTotalShares
    gpuTotalSharesDual
    gpuRej
    gpuRejDual
    gpuInv
    gpuInvDual
    gpuTotalTime
    gpuTotalTimeDual
    gpuErr
    miningString
    miner
    secondMiner
    coin
    coinDual
    miningPresetId
    rebootNum
    powCons
    powerOf
    reboot
    lowHash
    overclockingAlgo
    onGodAnEthArgPill
    autoTune
    p0
    amdmemorytweak
    overclockingPresetType
    statusDate
    osVersion
    osKernel
    backLight
    driverVersion
    remoteIp
    downTime
    average
    downloadMiner
    uptime
    getReqFromRigConsole
    rigErrorsTotal
    updatedAt
    spaceErrorUpdatedAt
    miningPreset {
      id
      name
      flightSheets{
        pool
      }
    }
    videoCards {
      id
      cardNum
      cardType
      cardSlot
      gpuName
      lowHash
      uuid
      vram
      vendor
      brand
      gpuClock
      memClock
      memTemp
      powerLimit
      coreVddc
      fan
      hashCard
      hashCardDual
      blocksNumber
      blocksNumberDual
      badBlocsNumber
      badBlocsNumberDual
      invalidShares
      invalidSharesDual
      gpuTemp
      fanInfo
      fanInfo1
      gpuLoad
      gpuClockStat
      memClockStat
      powerInfo
      coreVddc
      dpm
      mdpm
      memVddc
      detected
      newCard
      vendorName
      memInfo
      memType
      fwInfo
      nvGpuPwrMin
      nvGpuPwrMax
      DefPwrLimit
      currentSpeed
      currentSpeed1
      socFrequency
      memControlVoltage
      socVDDmax
      offMining
      critTemp
      highTemp
      updatedAt
    }
    rigSettings {
      id
      updateInterval
      maintenanceMode
      powerCycle
      minerStartDelay
      lowHashScope
      critHashScope
      powerConsumption
      psuEfficiency
      createdAt
      updatedAt
    }
    overclockingAllCardsData {
        id
        gpuClock
        memClock
        fan
        powerLimit
        coreVddc
        memVddc
        dpm
        mdpm
        socFrequency
        memControlVoltage
        socVDDmax
      }
  }
}
`

const CREATE_RIG = `
  mutation createRig ($name: String!, $password: String!, $roomId: ID, $dcId: ID) {
    createRig(name: $name, password: $password, roomId: $roomId, dcId: $dcId) {
      id
      name
      dcId
      password
      createdAt
      updatedAt
    }
  }
`

const UPDATE_RIG = `
  mutation updateRig ($id: ID!, $miningPresetId: ID, $name: String!, $reboot: Boolean, $description: String) {
    updateRig(id: $id, miningPresetId: $miningPresetId, name: $name, reboot: $reboot, description: $description) 
  }
`

const OVERCLOCKING_CARDS = `
  mutation overclockingAll ($nvidiaCards: String!, $amdCards: String!, $action: String!, $powerLimit: String, $gpuClock: String, $memClock: String, $fan: String, $memClockAMD: String, $gpuClockAMD: String, $fanAMD: String, $coreVddc: String, $memVddc: String, $dpm: String, $mdpm: String, $overclockingAlgo: String, $amdmemorytweak: String, $onGodAnEthArgPill: String, $autoTune: String, $memControlVoltage: String, $socFrequency: String, $socVDDmax: String,$p0:Boolean) {
    overclockingAll (nvidiaCards: $nvidiaCards, amdCards: $amdCards, action: $action, powerLimit: $powerLimit, gpuClock: $gpuClock, memClock: $memClock, fan: $fan, memClockAMD: $memClockAMD, gpuClockAMD: $gpuClockAMD, fanAMD: $fanAMD, coreVddc: $coreVddc, memVddc: $memVddc, dpm: $dpm, mdpm: $mdpm, overclockingAlgo: $overclockingAlgo, amdmemorytweak: $amdmemorytweak, onGodAnEthArgPill: $onGodAnEthArgPill, autoTune: $autoTune, memControlVoltage: $memControlVoltage, socVDDmax: $socVDDmax, socFrequency: $socFrequency,p0:$p0) {
      id
      overclockingAlgo
      onGodAnEthArgPill
      amdmemorytweak
      autoTune
      p0
      overclockingPresetType
      overclockingAllCardsData {
          id
          gpuClock
          memClock
          fan
          powerLimit
          coreVddc
          memVddc
          dpm
          mdpm
          socFrequency
          memControlVoltage
          socVDDmax
          action
        }
      videoCards {
        id
          rigId
          cardType
          cardSlot
          gpuName
          vram
          vendor
          brand
          gpuClock
          memClock
          powerLimit
          coreVddc
          fan
          action
          hashCard
          blocksNumber
          badBlocsNumber
          invalidShares
          gpuTemp
          fanInfo
          gpuLoad
          powerInfo
          cardNum
          coreVddc
          dpm
          mdpm
          memVddc
          socFrequency
          memControlVoltage
          socVDDmax
          detected
          newCard
          vendorName
          memInfo
          memType
          fwInfo
      }
    }
  } 
`

const OVERCLOCKING_EACH_CARD = `
  mutation overclockingEach ($rigId: ID, $action: String!, $cards: [VideoCardInput], $overclockingAlgo: String, $amdmemorytweak: String, $onGodAnEthArgPill: String, $autoTune: String,$p0:Boolean) {
    overclockingEach (action: $action, cards: $cards, rigId: $rigId, overclockingAlgo: $overclockingAlgo, amdmemorytweak: $amdmemorytweak, onGodAnEthArgPill: $onGodAnEthArgPill, autoTune: $autoTune,p0:$p0) {
      id
      overclockingAlgo
      onGodAnEthArgPill
      amdmemorytweak
      autoTune
      p0
      overclockingPresetType
      overclockingAllCardsData {
          id
          gpuClock
          memClock
          fan
          powerLimit
          coreVddc
          memVddc
          dpm
          mdpm
          socFrequency
          memControlVoltage
          socVDDmax
          action
        }
      videoCards {
        id
          rigId
          cardType
          cardSlot
          gpuName
          vram
          vendor
          brand
          gpuClock
          memClock
          powerLimit
          coreVddc
          fan
          action
          hashCard
          blocksNumber
          badBlocsNumber
          invalidShares
          gpuTemp
          fanInfo
          gpuLoad
          powerInfo
          cardNum
          coreVddc
          dpm
          mdpm
          socFrequency
          memControlVoltage
          socVDDmax
          memVddc
          detected
          newCard
          vendorName
          memInfo
          memType
          fwInfo
      }
    }
  }
`

const DELETE_RIG = `
  mutation deteteRig($rigId: ID!) {
    deleteRig(rigId: $rigId)
  }
`
const ACTION_CARD = `
  mutation actionCard($cardId: ID!, $offMining: Boolean) {
    actionCard(cardId: $cardId, offMining: $offMining)
  }
`

const SET_ACTION_RIG_MINER = `
  mutation actionRig($rigId: ID!,$miningPresetId: ID, $action: String, $backLight: Boolean, $redetect: Boolean, $setConsole: Boolean) {
    actionRig(rigId: $rigId,miningPresetId: $miningPresetId, action: $action, backLight: $backLight, redetect: $redetect, setConsole: $setConsole) {
      id
      miningPresetId
      miner
      coin
      coinDual
      action
      backLight
      getReqFromRigConsole
      mpName
    }
  }
`

const SET_CONSOLE = `
  mutation actionRig($rigId: ID!,$miningPresetId: ID, $action: String, $backLight: Boolean, $redetect: Boolean, $setConsole: Boolean, $setSystemConsole: Boolean) {
    actionRig(rigId: $rigId,miningPresetId: $miningPresetId, action: $action, backLight: $backLight, redetect: $redetect, setConsole: $setConsole, setSystemConsole: $setSystemConsole) {
      id
      getReqFromRigConsole
      rigConsole
    }
  }
`

const GET_CONSOLE_DATA = `
  query consoleRig($rigId: ID!, $setSystemConsole: Boolean) {
    consoleRig(rigId: $rigId, setSystemConsole: $setSystemConsole) {
      rigConsole
      getReqFromRigConsole
    }
  }
`

const SET_ACTION_RIGS = `
  mutation actionRigs($rigIds: String!,$miningPresetId: ID,$action: String, $powerOff: Boolean, $reboot: Boolean, $hardReboot: Boolean) {
    actionRigs(rigIds: $rigIds,miningPresetId: $miningPresetId, action: $action, powerOff: $powerOff, reboot: $reboot, hardReboot: $hardReboot)
  }
`

const SET_WORKER_REBUT = `
  mutation powerOfRebootRig($rigId: ID!, $powerOf: Boolean, $reboot: Boolean, $hardReboot: Boolean) {
    powerOfRebootRig(rigId: $rigId, powerOf: $powerOf, reboot: $reboot, hardReboot: $hardReboot) 
  }
`

const RIG_LOGS_LIST = `
query rigLogsList($page: Int, $size: Int, $filters: RigLogsFilters!) {
  rigLogsList(page: $page, size: $size, filters: $filters) {
    items {
      id
      workerId
      userId
      changeUserId
      cardUuid
      action
      description
      createdAt
      logsNum
      
    }
    total
  }
}
`

const UPDATE_RIG_SETTINGS = `
mutation updateRigSettings($rigId: ID!, $name: String, $miningPresetId: ID, $description: String, $reboot: Boolean, $updateInterval: String, $maintenanceMode: String, $powerCycle: String, $minerStartDelay: Int, $lowHashScope: Int, $critHashScope: Int, $powerConsumption: Int, $psuEfficiency: String) {
  updateRigSettings(rigId: $rigId, name: $name, description: $description, reboot: $reboot, miningPresetId: $miningPresetId, updateInterval: $updateInterval, maintenanceMode: $maintenanceMode, powerCycle: $powerCycle, minerStartDelay: $minerStartDelay, lowHashScope: $lowHashScope, critHashScope: $critHashScope, powerConsumption: $powerConsumption, psuEfficiency: $psuEfficiency ) {
    action
    name
    miningPresetId
    coin
    coinDual
    miner
    mpName
  }
}
`

const UPDATE_RIGS = `
  mutation updateRigs($userId: ID!) {
    updateRigs(userId: $userId) 
  }
`

const DELETE_LOGS = `
  mutation deleteRigLogs($workerId: ID!) {
    deleteRigLogs(workerId: $workerId)
  }
`

const UPDATE_RIGERROR = `
  mutation updateRigError ($errorsIds: String! $action: String!, $fallenOffTheBusErrorId: String, $rigId: String, $userId: String) {
    updateRigError(errorsIds: $errorsIds, action: $action, fallenOffTheBusErrorId: $fallenOffTheBusErrorId, rigId: $rigId, userId: $userId)
  }
`

const WORKER_UPGRADE = `
  mutation downloadUpdates ($rigIds: String! $osVersion: String, $forceUpdate: Boolean!, $driverVersion: String) {
    downloadUpdates(rigIds: $rigIds, osVersion: $osVersion, forceUpdate: $forceUpdate, driverVersion: $driverVersion)
  }
`

const SET_RIG_STATS = `
  query rigStatList($rigId: ID, $typeGraph: String) {
    rigStatList(rigId: $rigId, typeGraph: $typeGraph) {
      items {
        gpu_total_speed
        gpu_online
        gpu_ofline
        pow_cons
        gpu_total_shares
        gpu_rej
        gpu_inv
        rig_fan
        rig_temp
        updatedat
        uptime
        downtime
      }
      total
    }
  }
`

const SET_OS_VERSIONS = `
  query osVersionsList($page: Int, $size: Int) {
    osVersionsList(page: $page, size: $size) {
      items {
        id
        vers
      }
      type
    }
  }
`

const SET_DRIVERS = `
  query driversList($page: Int, $size: Int) {
    driversList(page: $page, size: $size) {
      items {
        id
        vers
      }
      type
    }
  }
`

const SET_WORKERS_MPS = `
  query rigsMPs {
    rigsMPs
  }
`

const RIG_ERRORS_LIST = `
query rigErrorsList($page: Int, $size: Int, $rigId: Int!) {
  rigErrorsList(page: $page, size: $size, rigId: $rigId) {
    items {
      id
      rigId
      userId
      cardUuid
      error
      action
      actionAt
      createdAt
      updatedAt
      errorsnum
    }
    total
  }
}
`

export const getRigErrors =
  ({ rigId }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: RIG_ERRORS_LIST,
        variables: { rigId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rigErrorsList } = data
      if (!rigErrorsList) {
        throw new Error("Can't get rig errors")
      }

      dispatch({
        type: types.RIG_ERRORS_LIST,
        data: rigErrorsList,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        isRigLoading: false,
      })
    }
  }

// dop filters rigs list
export const setDopFilterRigsList =
  ({
    page,
    size,
    orderBy,
    orderDirection,
    favoriteFirst,
    dc,
    type,
    status,
    coins,
    mpsIds,
    minersIds,
    poolsIds,
    walletsIds,
    rejectedShares = false,
    tempMin,
    tempMax,
  }) =>
  async dispatch => {
    try {
      if (type !== '10sec') {
        dispatch({
          type: 'SET_LOADING_STATE',
          isRigListLoading: true,
        })
        dispatch({
          type: types.SET_FILTER_APPLIED,
          data: true,
        })
      } else {
        dispatch({
          type: types.SET_FILTER_APPLIED,
          data: false,
        })
      }
      let dcId, roomId

      if (dc && dc.value.indexOf('room') >= 0 && dc.value !== 'all') roomId = +dc.value.replace('room', '')
      else if (dc && dc.value !== 'all') dcId = +dc.value

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DOP_FILTER_RIGS,
        variables: {
          page,
          size,
          orderBy,
          orderDirection,
          favoriteFirst,
          dcId,
          roomId,
          status,
          coins,
          mpsIds,
          minersIds,
          poolsIds,
          walletsIds,
          rejectedShares,
          tempMin,
          tempMax,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { dopFiltersRigs, total } = data
      if (!dopFiltersRigs) {
        throw new Error("Can't get rigs")
      }
      if (type !== '10sec') {
        const dopFilter = {
          dcId,
          roomId,
          orderBy,
          status,
          coins,
          mpsIds,
          minersIds,
          poolsIds,
          walletsIds,
          rejectedShares,
          tempMin,
          tempMax,
        }

        await dispatch({
          type: types.SET_RIGS_LIST_FILTERED,
          data: dopFiltersRigs,
          total,
          dopFilter,
          filter: null,
        })
        console.log('setDopFilterRigsList')
        await dispatch({
          type: 'SET_LOADING_STATE',
          isRigListLoading: false,
        })
      } else {
        dispatch({
          type: types.SET_RIGS_DATA,
          data: dopFiltersRigs,
        })
      }
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        isRigListLoading: false,
      })
    }
  }

// rigs List
export const setRigsMPs = () => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      isRigListLoading: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_WORKERS_MPS,
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    dispatch({
      type: types.SET_WORKERS_MPS,
      data: data.rigsMPs,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      isRigListLoading: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      isRigListLoading: false,
    })
  }
}
// rigs List
export const setRigsList =
  ({ page, size, orderBy, orderDirection, favoriteFirst, filters = {}, type = '', search }) =>
  async dispatch => {
    try {
      dispatch({
        type: types.SET_DOWNLOAD_RIGS,
        data: true,
      })

      if (type !== '10sec') {
        dispatch({
          type: 'SET_LOADING_STATE',
          isRigListLoading: true,
        })
      } else {
        dispatch({
          type: types.SET_FILTER_APPLIED,
          data: false,
        })
      }

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_RIGS_LIST,
        variables: { page, size, orderBy, orderDirection, favoriteFirst, filters, search },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rigsList } = data
      if (!rigsList) {
        throw new Error("Can't get rigs")
      }

      if (rigsList) {
        if (type !== '10sec') {
          dispatch({
            type: types.SET_FILTER_APPLIED,
            data: true,
          })
          await dispatch({
            type: types.SET_RIGS_LIST_FILTERED,
            data: rigsList,
            search,
            filter: null,
            dopFilter: null,
          })
          dispatch({
            type: 'SET_LOADING_STATE',
            isRigListLoading: false,
          })
        } else {
          dispatch({
            type: types.SET_RIGS_DATA,
            data: rigsList,
          })
        }
        dispatch({
          type: types.SET_DOWNLOAD_RIGS,
          data: false,
        })
      }
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        isRigListLoading: false,
      })
    }
  }

// rigs List
export const setFilterRigsList =
  ({
    page,
    size,
    orderBy,
    orderDirection,
    favoriteFirst,
    dc,
    type,
    online = false,
    offline = false,
    haveAlerts = false,
    notDetectedGPU = false,
    offlineGPU = false,
    amdGPU = false,
    nvidiaGPU = false,
    highTempGPU = false,
    otherProblemsGPU = false,
    minTemp = false,
    maxTemp = false,
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: types.SET_DOWNLOAD_RIGS,
        data: true,
      })
      if (type !== '10sec') {
        dispatch({
          type: 'SET_LOADING_STATE',
          isRigListLoading: true,
        })
        dispatch({
          type: types.SET_FILTER_APPLIED,
          data: true,
        })
      } else {
        dispatch({
          type: types.SET_FILTER_APPLIED,
          data: false,
        })
      }
      let dcId, roomId

      if (dc && dc.value.indexOf('room') >= 0 && dc.value !== 'all') roomId = +dc.value.replace('room', '')
      else if (dc && dc.value !== 'all') dcId = +dc.value

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: FILTER_RIGS,
        variables: {
          page,
          size,
          orderBy,
          orderDirection,
          favoriteFirst,
          dcId,
          roomId,

          online,
          offline,
          haveAlerts,
          notDetectedGPU,
          amdGPU,
          nvidiaGPU,
          offlineGPU,
          highTempGPU,
          otherProblemsGPU,
          minTemp,
          maxTemp,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { filterRigs, total } = data
      if (!filterRigs) {
        throw new Error("Can't get rigs")
      }

      if (filterRigs) {
        if (type !== '10sec') {
          let filter = ''
          if (online) filter = 'online'
          if (offline) filter = 'offline'
          if (haveAlerts) filter = 'haveAlerts'
          if (notDetectedGPU) filter = 'notDetectedGPU'
          if (amdGPU) filter = 'amdGPU'
          if (nvidiaGPU) filter = 'nvidiaGPU'
          if (offlineGPU) filter = 'offlineGPU'
          if (highTempGPU) filter = 'highTempGPU'
          if (otherProblemsGPU) filter = 'otherProblemsGPU'
          if (minTemp) filter = 'minTemp'
          if (maxTemp) filter = 'maxTemp'

          await dispatch({
            type: types.SET_RIGS_LIST_FILTERED,
            data: filterRigs,
            total,
            filter,
            dopFilter: null,
          })
          await dispatch({
            type: 'SET_LOADING_STATE',
            isRigListLoading: false,
          })
        } else {
          dispatch({
            type: types.SET_RIGS_DATA,
            data: filterRigs,
          })
        }

        dispatch({
          type: types.SET_DOWNLOAD_RIGS,
          data: false,
        })
      }
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        isRigListLoading: false,
      })
    }
  }

// rigs Data
export const setRigsData = () => async dispatch => {
  try {
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_RIGS_DATA,
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { allRigsData } = data
    if (!allRigsData) {
      throw new Error("Can't get rigs data!")
    }
    dispatch({
      type: types.SET_RIGS_STATS,
      data: allRigsData,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
  }
}

export const getRig =
  ({ rigId, type }) =>
  async dispatch => {
    try {
      if (type !== '5sec') {
        dispatch({
          type: 'SET_LOADING_STATE',
          isRigLoading: true,
        })
      }

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_WORKER_INFORMATION,
        variables: { rigId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rigFront } = data
      if (!rigFront) {
        throw new Error("Can't get rig")
      }

      dispatch({
        type: types.SET_WORKER_INFORMATION,
        data: rigFront,
      })

      if (type !== '5sec') {
        dispatch({
          type: 'SET_LOADING_STATE',
          isRigLoading: false,
        })
      }
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        isRigLoading: false,
      })
    }
  }

export const getRigTime =
  ({ rigId }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_WORKER_TIME,
        variables: { rigId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rigUptimeDowntime } = data
      if (!rigUptimeDowntime) {
        throw new Error("Can't get rig")
      }
      dispatch({
        type: types.SET_WORKER_TIME,
        data: rigUptimeDowntime,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        isRigLoading: false,
      })
    }
  }

// clear rig info when worker change
export const clearRigFullInfo = () => dispatch => {
  dispatch({
    type: types.SET_WORKER_INFORMATION,
    data: {},
  })
  return true
}

export const setOverclockingData =
  ({ rigId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      if (rigId === null) {
        dispatch({
          type: types.SET_OVERCLOCKING_DATA,
          data: {},
        })
      } else {
        const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
          query: SET_OVERCLOCKING_DATA,
          variables: { rigId },
        })

        guardFromErrors(errors)

        if (!data) {
          throw new Error('Response body is empty!')
        }

        const { rigOverclocking } = data
        if (!rigOverclocking) {
          throw new Error("Can't get rig")
        }

        dispatch({
          type: types.SET_OVERCLOCKING_DATA,
          data: rigOverclocking,
        })
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const updateRigs =
  ({ userId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_RIGS,
        variables: { userId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateRigs } = data
      if (!updateRigs) {
        throw new Error("Can't Update Workers")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Data Sended!', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

export const getConsoleData = formData => async dispatch => {
  try {
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: GET_CONSOLE_DATA,
      variables: { ...formData },
    })
    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { consoleRig } = data
    if (!consoleRig) {
      throw new Error("Can't get console data for worker!")
    }

    dispatch({
      type: types.GET_CONSOLE_DATA,
      data: consoleRig,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setConsole = formData => async dispatch => {
  try {
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_CONSOLE,
      variables: { ...formData },
    })
    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { actionRig } = data
    if (!actionRig) {
      throw new Error("Can't get console data for worker!")
    }

    dispatch({
      type: types.SET_CONSOLE,
      data: { getReqFromRigConsole: formData.setConsole, rigConsole: actionRig.rigConsole },
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setRigMinerAction = formData => async dispatch => {
  try {
    if (!formData.delay) {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
    }
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_ACTION_RIG_MINER,
      variables: { ...formData },
    })
    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { actionRig } = data
    if (!actionRig) {
      throw new Error("Can't action Miner/Rig")
    }
    console.log('🚀 ~ setRigMinerAction ~ actionRig:', actionRig)

    dispatch({
      type: types.SET_ACTION_PRESET_RIG,
      data: actionRig,
    })

    if (!formData.delay) {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      dispatch(addAlert('Action Applied Successfully', 'success'))
    }
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setRigsAction = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_ACTION_RIGS,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { actionRigs } = data
    if (!actionRigs) {
      throw new Error("Can't action Miner/Worker")
    }

    dispatch({
      type: types.SET_ACTION_RIGS,
      data: actionRigs,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Action Workers Successfully', 'success'))
    return actionRigs
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const setRigMiner = formData => async dispatch => {
  try {
    console.log('🚀 ~ setRigMiner ~ setRigMiner:')
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_ACTION_RIG_MINER,
      variables: {
        rigId: formData.rigId,
        miningPresetId: formData.miningPresetId,
        action: formData.action,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { actionRig } = data
    if (!actionRig) {
      throw new Error("Can't set Miner/Rig")
    }
    console.log('🚀 ~ setRigMiner ~ actionRig:', actionRig)

    dispatch({
      type: types.SET_ACTION_PRESET_RIG,
      data: actionRig,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    console.error('in action')
    dispatch(addAlert('Preset Applied Successfully', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

// delete rig
export const deleteRig =
  ({ rigId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DELETE_RIG,
        variables: { rigId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { deleteRig } = data
      if (!deleteRig) {
        throw new Error("Can't get rigs")
      }

      dispatch({
        type: types.DELETE_RIG,
        data: rigId,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Worker deleted Successfully', 'success'))
      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

// delete card
export const deleteCard =
  ({ rigId, cardId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: ACTION_CARD,
        variables: { cardId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { actionCard } = data
      if (!actionCard) {
        throw new Error("Can't get Card")
      }

      dispatch({
        type: types.DELETE_CARD,
        data: { rigId, cardId },
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Card deleted Successfully', 'success'))
      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

//////////////////////////// OFF MINING //////////////////////////
export const offMiningCard =
  ({ rigId, cardId, offMining }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: ACTION_CARD,
        variables: { cardId, offMining },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { actionCard } = data
      if (!actionCard) {
        throw new Error("Can't get Card")
      }

      dispatch({
        type: types.OFF_MINING_CARD,
        data: { rigId, cardId, offMining },
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Action Card Successfully', 'success'))
      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

// create rig
export const createRig = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_RIG,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createRig } = data
    if (!createRig) {
      throw new Error("Can't create rig")
    }

    dispatch({
      type: types.CREATE_RIG,
      data: createRig,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert(`Rig Created Successfully!`, 'success'))

    return createRig
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

// update rig
export const updateRig = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_RIG,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateRig } = data
    if (!updateRig) {
      throw new Error("Can't update rig")
    }

    dispatch({
      type: types.UPDATE_RIG,
      data: formData,
    })

    if (formData.setLoadingState) {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }

    dispatch(addAlert(`Rig Updated Successfully!`, 'success'))
    return true
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    return false
  }
}

// create rig
export const overclocking = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: OVERCLOCKING_CARDS,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { overclockingAll } = data
    if (!overclockingAll) {
      throw new Error("Can't overclocking card")
    }

    dispatch({
      type: types.OVERCLOCKING_CARDS,
      data: overclockingAll,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    dispatch(addAlert('Cards Overclocked Successfully', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const overclockingEach = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: OVERCLOCKING_EACH_CARD,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }
    const { overclockingEach } = data
    if (!overclockingEach) {
      throw new Error("Can't overclocking card")
    }

    dispatch({
      type: types.OVERCLOCKING_CARDS,
      data: overclockingEach,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(addAlert('Cards Overclocked Successfully', 'success'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

export const resetOrPowerOfWorker = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SET_WORKER_REBUT,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { powerOfRebootRig } = data

    if (!powerOfRebootRig) {
      throw new Error("Can't Action Worker")
    } else {
      dispatch({
        type: types.REBOOT_POWEROF_RIG,
        data: formData,
      })
      dispatch(addAlert('Worker Action Succes', 'success'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return powerOfRebootRig
    }
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log('errorMessage: ', errorMessage)
    dispatch(addAlert(errorMessage, 'danger'))
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  }
}

// logs List
export const getRigLogs =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: RIG_LOGS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rigLogsList } = data
      if (!rigLogsList) {
        throw new Error("Can't get rig logs")
      }

      dispatch({
        type: types.RIG_LOGS_LIST,
        data: rigLogsList,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return false
    }
  }
// clear logs when worker change
export const clearRigLogs = () => dispatch => {
  dispatch({
    type: types.RIG_LOGS_LIST,
    data: {
      items: [],
      total: 0,
    },
  })
  return true
}

// delete all worker logs
export const deleteRigLogs =
  ({ workerId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: DELETE_LOGS,
        variables: { workerId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { deleteRigLogs } = data
      if (!deleteRigLogs) {
        throw new Error("Can't delete logs")
      }

      dispatch({
        type: types.DELETE_LOGS,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch({
        type: types.DELAY_RIG_UPDATE,
        data: true,
      })

      setTimeout(() => {
        dispatch({
          type: types.DELAY_RIG_UPDATE,
          data: false,
        })
      }, 5000)

      dispatch(addAlert('Logs Cleared Successfully', 'success'))
      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    }
  }

// Update Rig Settings
export const updateRigSettings =
  ({
    rigId,
    name,
    description,
    miningPresetId,
    reboot,
    updateInterval,
    maintenanceMode,
    powerCycle,
    minerStartDelay,
    lowHashScope,
    critHashScope,
    powerConsumption,
    psuEfficiency,
    workerUpdate,
    minerUpdate,
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_RIG_SETTINGS,
        variables: {
          rigId,
          name,
          description,
          miningPresetId,
          reboot,
          updateInterval,
          maintenanceMode,
          powerCycle,
          minerStartDelay,
          lowHashScope,
          critHashScope,
          powerConsumption,
          psuEfficiency,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateRigSettings } = data
      if (!updateRigSettings) {
        throw new Error("Can't update settings!")
      }

      if (workerUpdate) {
        dispatch({
          type: types.UPDATE_RIG,
          data: { name, description, id: rigId },
        })
      }

      if (minerUpdate) {
        dispatch({
          type: types.SET_ACTION_PRESET_RIG,
          data: {
            action: updateRigSettings.action,
            coin: updateRigSettings.coin,
            coinDual: updateRigSettings.coinDual,
            id: rigId,
            miner: updateRigSettings.miner,
            miningPresetId: updateRigSettings.miningPresetId,
            mpName: updateRigSettings.mpName,
          },
        })
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Settings Have Been Saved Successfully', 'success'))
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return false
    }
  }

// Update Rig Errors
export const updateRigError =
  ({ errorsIds, action, fallenOffTheBusErrorId, rigId, userId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_RIGERROR,
        variables: {
          errorsIds,
          action,
          fallenOffTheBusErrorId,
          rigId,
          userId,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateRigError } = data
      if (!updateRigError) {
        throw new Error("Can't update error!")
      }

      dispatch({
        type: types.UPDATE_RIGERROR,
        data: errorsIds,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      const alertText = action === 'erase' ? 'Error Erased Successfully' : 'Error Ignored Successfully'
      dispatch(addAlert(alertText, 'success'))

      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return false
    }
  }

//  WORKER_UPGRADE
export const downloadUpdates =
  ({ rigIds, osVersionUpd, driverVersionUpd, forceUpdate }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      if (osVersionUpd && driverVersionUpd) {
        throw new Error('You can select an update only OS version or driver version!')
      }

      if (!osVersionUpd && !driverVersionUpd) {
        throw new Error('Нou need to choose one of the version options!')
      }

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: WORKER_UPGRADE,
        variables: {
          rigIds,
          osVersion: osVersionUpd,
          driverVersion: driverVersionUpd,
          forceUpdate,
        },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { downloadUpdates } = data
      if (!downloadUpdates) {
        throw new Error("Can't update Worker!")
      }

      // dispatch({
      //   type: types.WORKER_UPGRADE,
      //   data: {
      //     rigIds,
      //     osVersionUpd,
      //   },
      // })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })

      dispatch(addAlert('Worker Updated Successfully', 'success'))

      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return false
    }
  }

// rigs stats for chart-line
export const setRigStats =
  ({ rigId, typeGraph = {}, isLoadingNeeded = true }) =>
  async dispatch => {
    try {
      if (isLoadingNeeded) {
        dispatch({
          type: 'SET_LOADING_STATE',
          active: true,
        })
      }

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_RIG_STATS,
        variables: { rigId, typeGraph },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rigStatList } = data
      if (!rigStatList) {
        throw new Error("Can't get Rig Stats")
      }

      dispatch({
        type: types.SET_RIG_STATS,
        data: rigStatList,
      })

      if (isLoadingNeeded) {
        dispatch({
          type: 'SET_LOADING_STATE',
          active: false,
        })
      }

      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return false
    }
  }

// when delete cards, logs or errors
export const delayRigUpdate = formData => async dispatch => {
  try {
    dispatch({
      type: types.DELAY_RIG_UPDATE,
      data: formData,
    })

    setTimeout(() => {
      dispatch({
        type: types.DELAY_RIG_UPDATE,
        data: false,
      })
    }, 5000)

    return true
  } catch (errors) {
    return false
  }
}

export const setOsVersions =
  ({ page, size }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_OS_VERSIONS,
        variables: { page, size },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { osVersionsList } = data
      if (!osVersionsList) {
        throw new Error("Can't get OS versions!")
      }

      dispatch({
        type: types.SET_VERSIONS,
        data: osVersionsList,
      })

      return osVersionsList.items
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      return false
    }
  }

export const setDrivers =
  ({ page, size }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_DRIVERS,
        variables: { page, size },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { driversList } = data
      if (!driversList) {
        throw new Error("Can't get OS versions!")
      }

      dispatch({
        type: types.SET_VERSIONS,
        data: driversList,
      })

      return driversList.items
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      console.log('errorMessage: ', errorMessage)
      dispatch(addAlert(errorMessage, 'danger'))
      return false
    }
  }
